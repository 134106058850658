.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 250px;
    height: 40px;
    font-family: 'OpenSans-Bold';
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 20px;
    transition: 0.3s ease;
    &:not(.btn-blue):hover {
        color: #3f43af;
        border: 1px solid #3f43af;
    }
}

.btn-blue {
    max-width: 178px;
    height: 41px;
    font-family: 'OpenSans-Regular';
    border: 1px solid #ffffff;
    background: rgb(51,55,157);
    background: linear-gradient(270deg, rgba(51,55,157,1) 0%, rgba(15,18,89,1) 100%);
    border:none;
    transition: 0.3s ease;
}

.btn-clear {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    line-height: 14px;
    color: #959595;
    background: transparent;
    text-decoration: underline;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
        text-decoration: none;
        color: #3f43af;
    }
}