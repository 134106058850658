.videos-list-wrap {
  padding-bottom: 32px;
  .pages-links-wrapper {padding-top: 14px;}
}

.gallery-wrap {padding-bottom: 57px;}

.videos-list,
.photos-list,
.gallery {
  display: flex;
  justify-content: space-between;
  &:not(.popular-videos) {
    flex-flow: row wrap;
    .video, .photo, .gallery-item {margin-bottom: 17px;}
  }
  .video, .photo, .gallery-item {
    width: 100%;
    max-width: 262px;
    box-shadow: 0 0 15px rgba(9, 12, 59, 0.2);
  }
  .gallery-item {
    width: 100%;
    max-width: 259px;
    box-shadow: none;
  }
  .video .ifame {
    width: 100%;
    height: auto;
  }
  .video-cnt,
  .photo-cnt,
  .gallery-item-cnt {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
		height: 100%;
    max-height: 140px;
    overflow: hidden;
		width: 100%;
		background-color: #ffffff;
    & img {
      width: 100%;
			height: auto;
			max-height: 148px;
    }
    .video-cnt-layout,
    .photo-cnt-layout,
    .gallery-item-layout {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      transition: 0.3s ease;
      & img {
        width: 40px;
        height: 40px;
        transition: 0.3s ease;
      }
      &:hover {
        & img {transform: scale(1.05);}
      }
    }
    &:hover {
      & .video-cnt-layout ,
      & .photo-cnt-layout,
      & .gallery-item-layout {opacity: 1;}
    }

    .video-cnt-layout img {display: none;}
  }
  .gallery-item-cnt {
    max-height: 199px;
    & img {max-height: 199px;}
  }
  .video-desc,
  .photo-desc {
    width: 100%;
    padding: 23px 10px 17px;
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(9, 12, 59, 0.2);
    .video-title,
    .photo-title  {
      display: block;
      margin-bottom: 13px; 
      font-family: 'OpenSans-Bold';
      font-size: 13px;
			line-height: 13px;
			text-align: center;
			color: #33379d;
			transition: 0.3s ease;
			&:hover {color: #585858;}
    }
    .video-date,
    .photo-date {
      display: block;
      padding-right: 7px;
      font-family: 'OpenSans-Regular';
      font-size: 12px;
			line-height: 12px;
			text-align: right;
      color: #898989;
    }
  }
}

@media(max-width: 1100px){
  .videos-list .video,
  .photos-list .photo {max-width: 220px;}
  .videos-list .video-desc .video-title,
  .photos-list .photo-desc .photo-title {
    line-height: 16px;
    height: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  .videos-list .video-cnt,
  .photos-list .photo-cnt {max-height: 123px;}

  .videos-list .video-cnt .video-cnt-layout,
  .photos-list .photo-cnt .photo-cnt-layout,
  .gallery .gallery-item-cnt .gallery-item-layout {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.25);
  }
}
@media(max-width: 992px){
  .videos-list,
  .photos-list,
  .gallery {
    justify-content: space-around;
    flex-flow: row wrap;
    & .video,
    & .photo {
      max-width: 262px;
      &:not(:last-child) {margin-bottom: 20px;}
    }
    & .video-cnt,
    & .photo-cnt {
      max-height: 148px;
      & img {max-height: 148px;}
    }
    & .video-desc,
    & .photo-desc  {
      & .video-title,
      & .photo-title {
        height: auto;
      }
    }
  }
}
@media(max-width: 767px){
  .videos-list .video-desc .video-title,
  .photos-list .photo-desc .photo-title {margin-bottom: 10px;}
}