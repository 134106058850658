.section-contacts {
    .section-title {margin-bottom: 22px;}
}
.contacts{
    &-info {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 57px;
        @media(max-width: 992px){
            flex-direction: column;
            align-items: center;
        }
    }
    &-cnt {
        width: 100%;
        max-width: 390px;
        padding-top: 27px;
        padding-right: 5px;   
        @media(max-width: 992px){
            width: 100%;
            max-width: 100%;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 15px;
            & br {display: none;}
        } 
        &-row {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            &:not(:last-child) {padding-bottom: 23px;}
        }
        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            min-width: 25px;
            margin-top: 4px;
            margin-right: 22px;
            & img {
                &.location-icon {
                    width: 14px;
                    height: 20px;
                }
                &.telephon-icon {
                    width: 19px;
                    height: 20px;
                }
                &.mail-icon {
                    width: 25px;
                    height: 20px;
                }
                &.museum-icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
        &-text {
            & p,
            & a {
                display: block;
                font-family: 'OpenSans-Regular';
                font-size: 16px;
                line-height: 24px;
                color: #151515;
                transition: 0.3s ease;
                &:not(:last-child) {margin-bottom: 1px;}
                @media(max-width: 992px){
                    font-size: 14px;
                    line-height: 22px;
                }
            }
            & p:not(:last-child) {margin-bottom: 8px;}
            & a:hover {color: #33379d;}
        }
    }
    &-map {
        width: 100%;
        max-width: 730px;
        margin-right: -20px;
        @media(max-width: 992px){
            max-width: 100%;
            margin-right: 0px;
        }
    }
    &-form {
        & h2 {
            margin-bottom: 6px; 
            font-family: 'OpenSans-Bold';
            font-size: 24px;
            line-height: 24px;
            color: #33379d;
            @media(max-width: 992px){text-align: center;}
        }
        & p {
            margin-bottom: 11px; 
            font-family: 'OpenSans-Regular';
            font-size: 16px;
            line-height: 32px;
            color: #333333;
            @media(max-width: 992px){text-align: center;}
        }
        &-fields {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 7px;
            @media(max-width: 992px){
                flex-direction: column;
                align-items: center;
            }
            & div {
                margin-right: 15px;
                @media(max-width: 992px){
                    width: 100%;
                    max-width: 600px;
                    margin-right: 0;
                }
                & input {
                    max-width: 446px;
                    margin-bottom: 14px;
                    @media(max-width: 992px){max-width: 100%;}
                }
            }
            & textarea {
                max-width: 615px;
                height: 163px;
                margin-bottom: 14px;
                font-size: 14px;
                line-height: 20px;
                @media(max-width: 992px){max-width: 600px;}
            }
        }
        & .btn {
            @media(max-width: 992px){margin: 0 auto;}
        }
    }
}