.captain-check {
    width: 14px;
    height: 12px;
}

.protocol-section {
    .table-block {
        .protocol-table {
            @media(max-width: 720px) {
                overflow: scroll;
                & table {width: 680px;}
            }
        }
    }
    & table  {
        & tr {
            td, th {padding: 9px 15px 5px;}
        }
    }
}


.protocol-form {
    margin-top: 15px;
    padding: 11px 14px;
    border: 1px solid #2e3092;
    &-block {margin-top: 32px;}
    & h5 {
        margin-bottom: 15px;
        font-family: 'OpenSans-Bold';
        font-size: 18px;
        line-height: 18px;
        color: #2e3092;
    }
    &-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;
        & p {
            padding-bottom: 10px;
            width: 100%;
            max-width: 50%;
            font-family: 'OpenSans-Regular';
            font-size: 16px;
            line-height: 16px;
            color: #363636;
        }
    }
}

.protocol {
    h1 {margin-bottom: 0px;}
    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 44px;
        @media(max-width: 1140px){
            flex-direction: column;
            text-align: center;
        }
        p:nth-child(1) {
            font-family: 'OpenSans-Bold';
            font-size: 18px;
            line-height: 32px;
            color: #2e3092;
        }
        p:nth-child(2) {
            font-family: 'OpenSans-Light';
            font-size: 16px;
            line-height: 32px;
            color: #2e3092;
        }
    }
    &-info {
        margin-bottom: 15px;
        p {
            padding-bottom: 4px;
            font-family: 'OpenSans-Bold';
            font-size: 18px;
            line-height: 32px;
            color: #2e3092;
        }
        table {
            td {
                padding: 0 !important;
                font-family: 'OpenSans-Regular';
                font-size: 16px;
                line-height: 26px;
                color: #363636;
            }
        }
    }
}

.technical-info {
    @media(max-width: 1140px) {overflow: scroll;}
}