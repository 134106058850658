.logo-blk {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media(max-width: 992px) {
        flex-direction: column;
        justify-content: center;
    }
    & .logo-big-img {
        width: 100%;
        max-width: 633px;
        @media(max-width: 992px) {margin-bottom: 30px;}
    }
    .logo-download {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 90px;
        @media(max-width: 992px) {padding-left: 0;}
        & a {
            display: flex;
            align-items: center;
            justify-content: center; 
            font-family: 'OpenSans-Regular';
            font-size: 12px;
            line-height: 12px;
            text-decoration: underline;
            color: #585858;
            transition: 0.3s ease;
            &:not(:last-child) {margin-bottom: 17px;}
            &:hover {
                text-decoration: none;
                color: #3f43af;
            }
            & img {
                width: 37px;
                margin-right: 11px;
            }
        }
    }
}

