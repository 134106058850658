.pages-links-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
		padding-top: 30px;
		@media(max-width: 767px) {
			justify-content: center;
			padding-top: 10px;
		}
}

.pages-links {
    display: flex;
    align-items: center;
    justify-content: center;
    & a {
      font-family: 'OpenSans-Light';
      font-size: 16px;
      line-height: 16px;
      color: #54504f;
			transition: 0.3s ease;
			&:not(.pages-links-arrows) {padding: 0 13px;}
			&:hover {
				color: #3f43af;
			}
		}
		.pages-links-arrows {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 31px;
			min-width: 31px;
			height: 31px;
			border: 1px solid #b7b7b7;
			&.prev-page::before{
				content: "<";
				position: absolute;
				top: calc(50% - 7px);
				left: calc(50% - 6px);
				color: #54504f;
			}
			&.next-page::before{
				content: ">";
				position: absolute;
				top: calc(50% - 7px);
				left: calc(50% - 6px);
				color: #54504f;
			}
			&:hover {
				border: 1px solid #3f43af;
				&::before {
					color: #3f43af !important;
				}
			}
		}
}