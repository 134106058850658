.news-slider-section {padding-bottom: 110px;}

.news-slider-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 992px) {
        align-items: flex-start;
    }
}

.news-slider {
    width: 100%;
    max-width: 672px;
    margin-right: 20px;
    .news-slider-item {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 0 0 10px 0;
        & img {width: 100%;}
        .news-slider-cnt {
            position: absolute;
            bottom: 10px;
            left: 10px;
            display: block;
            width: 100%;
            max-width: 338px;
            padding: 18px 10px 20px 21px;
            font-family: 'OpenSans-Bold';
            font-size: 21px;
            line-height: 32px;
            color: #33379d;
            background-color: #ffffff;
            box-shadow: 0 0 10px rgba(9, 12, 59, 0.2);
            transition: 0.3s ease;
        }
        &:hover {
            .news-slider-cnt {color: #585858;} 
        }
    }
}

.news-previews {
    width: 100%;
    max-width: 417px;
    & .news-item{
        margin-bottom: 0;
        &:not(:last-child) {margin-bottom: 18px;}
        .news-head {
            -webkit-line-clamp: 3;
            height: 63px;
            margin-bottom: 14px;
        }
        .news-cnt {
            padding: 25px 17px 16px 26px;
            box-shadow: none;
            border: 1px solid #ebebeb;
        }
        .news-date {padding-right: 7px;}
    }
}

@media(max-width: 1140px) {
	.news-slider {max-width: 600px;}
}
@media(max-width: 1050px) {
	.news-slider {max-width: 550px;}
}
@media(max-width: 1100px) {
    .news-previews .news-item .news-cnt {padding: 15px;}
    .news-slider-section {padding-bottom: 26px;}
    .news-previews .news-item .news-head {
        height: 62px;
        font-size: 14px;
        line-height: 19px;
    }
}

@media(max-width: 992px) {
    .news-slider-wrap {
        flex-direction: column;
        align-items: center;
    }
    .news-slider {
        max-width: 650px;
        margin-right: 0;
        margin-bottom: 27px;
    }
    .news-slider .news-slider-item {margin: 0 0 40px;}
    .news-slider .news-slider-item .news-slider-cnt{
        bottom: -25px;
        left: 50%;
        max-width: 280px;
        transform: translateX(-50%);
        font-size: 16px;
        line-height: 22px;
        padding: 23px 17px 15px 21px;
    }
    .news-previews,
    .news-previews .news-item {max-width: 650px;}
}

@media(max-width: 767px){
    .news-slider {
        width: calc(100%);
        // margin-right: -10px;
    }
    .news-previews .news-item .news-head {margin-bottom: 12px;}
    .news-item .news-preview {
        max-width: 136px;
        & img {min-height: 102px;}
    }
    .news-item .news-date {
        font-size: 10px;
        padding-right: 0;
    }
    .news-item .news-head,
    .news-previews .news-item .news-head {
        height: 46px;
        font-size: 11px;
        line-height: 15px;
        color: #363636;
        -webkit-line-clamp: 3;
    }
    .news-item .news-cnt,
    .news-previews .news-item .news-cnt {
        padding: 21px 10px 8px 17px;
    }
}