.decision-section {
  padding-bottom: 69px;
  .pages-links-wrapper{padding-top: 20px;}
}
.decision {
  padding: 27px 0 27px 8px;
  @media(max-width: 550px) {
    padding: 20px 0;
  }
  &:not(:last-child) {border-bottom: 1px solid #ebebeb;}
  &-cnt {
    display: flex;
    // align-items: flex-start;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    @media(max-width: 550px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .decision-btn-block {
    @media(max-width: 550px) {
      order: 2;
      margin-bottom: 14px;
    }
  }
  &-btn  {
    &-block {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    margin-top: 7px;
    margin-right: 10px;
    @media(max-width: 550px) {
      margin: 3px !important;
    }
    & svg {
      width: 28px;
      height: 28px;
      & path {
        fill: #D9D9D9;
        transition: 0.3s ease;
      }
    }
    &:hover svg path {fill: #3f43af;}
    &.decision-download {margin-right: 16px;}
  }
  &-title {
    // min-height: 44px;
    // margin-bottom: 14px;
    font-family: 'OpenSans-Bold';
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    transition: 0.3s ease;
    &:hover {color: #3f43af;}
    @media(max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
    }
    @media(max-width: 550px) {
      text-align: center;
      order: 1;
    }
  }
  &-date-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media(max-width: 550px) {
      justify-content: center;
    }
    & span {
      font-family: 'OpenSans-Regular';
      font-style: italic;
      font-size: 14px;
      line-height: 14px;
      color: #363636;
      &.decision-date {margin-right: 4px;}
    }
  }
}