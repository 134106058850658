

header {
	.header-top {
		& .wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 20px;
			padding-bottom: 21px;
			padding-right: 22px;
			padding-left: 9px;
			background-color: #ffffff;
		}
		.header-top-left,
		.header-top-right {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
		.logo {margin-right: 55px;}
		.form-search {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			max-width: 220px;
			height: 43px;
			padding-right: 15px;
			border-radius: 22px;
			border: 1px solid #d7d7d7;
			overflow: hidden;
			transition: 0.3s ease;
		}
			.form-search:hover {border: 1px solid #33379d;}
			.form-search input {
				width: 100%;
				padding-left: 22px;
				padding-right: 5px;
				font-family: 'OpenSans-Regular';
				font-size: 14px;
				line-height: 14px;
				color: #acacac;
				background: transparent;
				&::-webkit-input-placeholder {color: #acacac;}
				&::-moz-placeholder {color: #acacac;}
				&:-ms-input-placeholder {color: #acacac;}
				&:-moz-placeholder {color: #acacac;}
			}
			.form-search button {
				background: none;
				outline: none;
				border: none;
			}
			.form-search button svg {
				width: 16px;
				height: 16px;
				transition: 0.3s ease;
			}
			.form-search button svg .cls-search {transition: 0.3s ease;}
			.form-search button:hover svg .cls-search {fill: #33379d;}
		.lang-trigger {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 91px;
			min-width: 91px;
			height: 41px;
			margin-right: 35px;
			border: 1px solid #33379d;
			border-radius: 20px;
			overflow: hidden;
		}
		.lang-trigger a,
		.lang-trigger span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			font-family: 'OpenSans-Bold';
			font-size: 16px;
			color: #0f1259;
			transition: 0.3s ease;
		}
		.lang-trigger a {padding-left: 4px;}
		.lang-trigger a:hover {text-decoration: underline;}
		.lang-trigger span {
			padding-right: 4px;
			font-family: 'OpenSans-Regular';
			color: #ffffff;
			background: #33379d;
		}
		
		.socials {
			display: flex;
			align-items: center;
			justify-content: center;
			& .social-link {
				display: flex;
				align-items: center;
				justify-content: center;
				&:not(:last-child) {margin-right: 18px;}
				& svg{
					width: 39px;
					height: 39px;
					& path {transition: 0.3s ease;}
				}
				&:hover {
					& svg {
						& path {fill: #33379d;}
					}
				}
			}
		}
	}
	& nav {
		.wrapper {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 0 20px;
			background: linear-gradient(270deg, rgba(51,55,157,1) 0%, rgba(15,18,89,1) 100%);
		}
		& a {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 29px 5px;
			font-family: 'OpenSans-Regular';
			font-size: 16px;
			line-height: 16px;
			color: #ffffff;
			transition: 0.3s ease;
			svg {
				margin-left: 10px;
				width: 8px;
				height: 4px;
				transition: 0.3s ease;
				path {transition: 0.3s ease;}
			}
			@media (min-width: 1025px) {
				&:hover {
					color: #3f43af;
					svg {
						transform: rotate(-180deg);
						path {fill: #3f43af;}
					}
				}
			}
		}
		.nav-link.active {
			& > a {
				color: #FC0B56;
				svg {
					transform: rotate(-180deg);
					path {fill: #FC0B56;}
				}
			}
		}
		.menu-hidden {
			position: absolute;
			top: 100%;
			left: 0;
			opacity: 0;
			z-index: -1;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-flow: row wrap;
			width: 100%;
			padding: 50px 79px 10px;
			background-color: rgba(0, 0, 0, 0.8);
			transition: 0.2s ease;
			& a {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				overflow: hidden;
				-webkit-box-orient: vertical;
				width: calc(100% / 5);
				height: 40px;
				padding: 0 5px 0 0;
				margin-bottom: 13px;
				line-height: 21px;
			}
		}
		@media (min-width: 1025px) {
			.nav-link:hover .menu-hidden {
				opacity: 1;
				z-index: 4;
			}	
		}
		.nav-link.active .menu-hidden {
			opacity: 1;
			z-index: 4;
		}
		&.fixed-nav {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 10;
			& .nav-link {
				& .menu-hidden {
					display: none;
				}
				&:hover {
					& .menu-hidden {
						display: flex;
					}
				}
			}
		}
	}
}

.header-mob.fixed-header {
	position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
	width: 100%;
	height: 76px;
    box-shadow: 0 0 10px #2F3396;
	& .logo {max-width: 65px;}
	& .menu-trigger {
		width: 40px;
		height: 26px;
		& span {
			width: 40px;
			height: 4px;
		}
	}
}

.header-mob {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    height: 122px;
	background-color: #ffffff;
	transition: box-shadow 0.3s ease;
	.logo {
		display: block;
		width: 100%;
		max-width: 106px;
		transition: 0.3s ease;
		& img {width: 100%;}
	}
	.menu-trigger {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-direction: column;
		background-color: transparent;
		width: 50px;
		height: 38px;
	}
	
	.menu-trigger span {
		display: block;
		width: 50px;
		height: 6px;
		background-color: #2F3396;
		border-radius: 3px;
		transition: 0.2s cubic-bezier(0, 0, 0.2, 1);
	}
}

.menu-mob-close {
	position: absolute;
	top: 30px;
	right: 25px;
	display: none;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	background: transparent;
	& img {width: 100%;}
}

@media(max-width: 1100px) {
	header nav a {
		font-size: 12px;
		line-height: 12px;
	}
	header nav .menu-hidden{padding: 15px 25px 10px;}
}
@media(max-width: 992px) {
	.header-mob,
	.menu-mob-close {display: flex;}
	.header {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 10;
		transform: translateX(320px);
		width: 100%;
		max-width: 315px;
		padding: 23px 37px 85px 24px;
		background: #ffffff;
		transition: 0.4s linear;
		&.open {
			transform: translateX(0px);
			max-height: 100vh;
			overflow: scroll;
			z-index: 11;
		}
		& .logo {display: none;}
		& .header-top-left {padding-bottom: 12px;}
		.header-top .wrapper {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 0;
			& .lang-trigger {margin-right: 22px;}
			& .socials .social-link {
				&:not(:last-child) {margin-right: 11px;}
				svg {
					width: 30px;
					height: 30px;
				}
			}
		}
		nav .wrapper {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			background: transparent;
			padding: 37px 0 0 11px;
			& a {padding: 0;}
			& .nav-link:not(:last-child) > a {margin-bottom: 30px;}
			& .nav-link.active:not(:last-child) {margin-bottom: 30px;}
			& .nav-link.active:last-child > a {margin-bottom: 30px;}
			.nav-link {
				width: 100%;
				& > a  {
					justify-content: space-between;
					font-family: 'OpenSans-Regular';
					font-size: 16px;
					line-height: 16px;
					color: #202379;
					svg {
						path {fill: #202379;}
					}
				}
			}
			.menu-hidden {
				display: none;
				position: static;
				opacity: 1;
				z-index: 1;
				flex-direction: column;
				padding: 0;
				background-color: transparent;
				& a {
					width: 100%;
					height: auto;
					font-size: 16px;
					line-height: 16px;
					color: #333333;
					&:not(:last-child) {margin-bottom: 15px;}
				}
			}
			.nav-link.active .menu-hidden{display: flex;}
		}
	}
}