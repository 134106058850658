footer {
	background: #111111;
	.wrapper {
		background-color: transparent !important;
		padding: 0;
	}
  .sponsors {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
		padding: 77px 35px 100px;
		& a {
			padding: 5px;
			& img {max-width: 100%;}
		}
	}
	.footer-columns {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		& .footer-column {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
			width: 100%;
			max-width: 215px;
			&-1 {max-width: 195px;}
			&-2 {max-width: 244px;}
			&-3 {max-width: 225px;}
			&-4 {max-width: 165px;}
		}
		.footer-column-list {
			&:not(:last-child) {margin-bottom: 19px;}
			& a {display: block;}
			& .footer-column-list-head {
				margin-bottom: 18px;
				font-family: 'OpenSans-Bold';
				font-size: 21px;
				line-height: 21px;
				color: #ffffff;
				transition: 0.3s ease;
			}
			& a:not(.footer-column-list-head) {
				font-family: 'OpenSans-Regular';
				font-size: 16px;
				line-height: 24px;
				color: rgba(255, 255, 255, 0.8);
				transition: 0.3s ease;
			}
			& a:hover {color: #33379d;}
		}
	}
	.footer-subscribe {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding: 0 0 62px;
		& .logo {
			width: 100%;
			max-width: 147px;
			margin-left: -45px;
			& img {width: 100%;}
		}
		form {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding-bottom: 23px;
			.form-input {
				width: 100%;
				max-width: 208px;
				/* height: 35px; */
				padding: 0 13px;
				font-size: 14px;
				line-height: 35px;
				font-family: 'OpenSans-Regular';
				background: transparent;
				color: rgba(255, 255, 255, 0.65);
				border: 1px solid rgba(255, 255, 255, 0.65);
				border-radius: 18px;
				transition: 0.3s ease;
				-webkit-appearance: none;
				&:hover,
				&:focus {
					border: 1px solid #33379d;
				}
				&::-webkit-input-placeholder,
				&:-ms-input-placeholder,
				&:-moz-placeholder
				&::-moz-placeholder {
					padding: 0 13px;
					font-size: 14px;
					line-height: 35px;
				}
			}
			& input:not(:last-child) {margin-right: 20px;}
			.form-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				max-width: 208px;
				height: 35px;
				padding: 0 13px;
				font-family: 'OpenSans-Bold';
				font-size: 16px;
				line-height: 35px;
				background: transparent;
				color: white;
				border: 1px solid white;
				border-radius: 18px;
				transition: 0.3s ease;
				&:hover {
					border: 1px solid #33379d;
					background-color: #33379d;
					color: #ffffff;
				}
			}
		}
		.socials {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 23px;
			& .footer-social {
				&:not(:last-child) {margin-right: 14px;}
				& svg {
					width: 25px;
					height: 25px;
					& path {
						fill: #ffffff;
						transition: 0.3s ease;
					}
				}
				&:hover {
					& svg {
						& path {fill: #33379d;}
					}
				}
			}
		}
	}
	.footer-bottom {
		width: 100%;
		padding: 40px 0;
		background-color: #000000;
		.wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			& p {
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: 'OpenSans-Regular';
				font-size: 14px;
				line-height: 14px;
				color: rgba(255, 255, 255, 0.8);
				transition: 0.3s ease;
				& img {margin-left: 5px;}
			}
		}
	}
}

@media(max-width: 1380px) {
	footer .sponsors {
		flex-flow: row wrap;
		justify-content: space-around;
		& a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			max-width: 25%;
			margin-bottom: 15px;
			& img {max-width: 167px;}
		}
	}
}

@media(max-width: 1160px) {
	footer .wrapper {padding: 0 20px;}
}
@media(max-width: 992px) {
	footer .footer-columns,
	footer .footer-subscribe .logo {display: none;}
	footer .footer-subscribe form {justify-content: flex-start;}
	footer .footer-subscribe form .form-input {max-width: 150px;}
}
@media(max-width: 767px) {
	footer .sponsors {
		padding: 40px 20px 50px;
		& a {max-width: 50%;}
	}
	footer .footer-subscribe{padding: 0 0 7px;}
	footer .footer-subscribe,
	footer .footer-bottom .wrapper {
		flex-direction: column;
		align-items: center;
	}
	.footer-subscribe form {
		flex-direction: column;
		padding-bottom: 31px;
	}
	footer .footer-bottom {padding: 30px 0;}
	footer .footer-subscribe form .form-input,
	footer .footer-subscribe form .form-btn {
		max-width: 275px;
		&:not(:last-child) {
			margin-right: 0;
			margin-bottom: 15px;
		}
	}
	footer .footer-subscribe form {padding-bottom: 39px;}
	footer .footer-bottom .wrapper p:not(:last-child) {padding:0 0 6px;}
	.sponsors a{max-width: inherit;}
	footer .sponsors {
		& a {
			max-width: fit-content;
			margin-bottom: 25px;
		}	
		.sponsor-1 img {max-width: 64px;}
		.sponsor-2 img {max-width: 172px;}
		.sponsor-3 img {max-width: 55px;}
		.sponsor-4 img {max-width: 166px;}
		.sponsor-5 img {max-width: 70px;}
		.sponsor-6 img {max-width: 193px;}
		.sponsor-7 img {max-width: 71px;}
		.sponsor-8 img {max-width: 167px;}
	}
}

@media(max-width: 350px) {
	footer .sponsors .sponsor-6 img {max-width: 185px;}
}