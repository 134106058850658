.song {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:not(:last-child) {margin-bottom: 16px;}
    & svg {
        width: 28px;
        height: 28px;
        & path {transition: 0.3s ease;}
    }
    & a {
        font-family: 'OpenSans-Regular';
        font-size: 12px;
        line-height: 12px;
        color: #585858;
        text-decoration: underline;
        &:hover {
            color: #3f43af;
            & svg path{
                fill: #3f43af;
            }
        }
        &.song-buttons {margin-right: 5px;}
        &.song-link {margin-left: 8px;}
    }
}