body {
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    background-attachment: fixed;
    overflow-x: hidden;
    @media(max-width: 992px) {
        &:not(.error-page-block) {background: #ffffff !important;}
    }
    &.error-page-block {
        background-image: url('../images/background.jpg') !important;
        background-position: center;
    }
}
.section {
    padding-bottom: 74px;
    &.section-news {padding-bottom: 45px;}
    &.section-personnel {padding-bottom: 33px;}
    &.section-contacts {padding-bottom: 56px;}
    @media(max-width: 767px){
        padding-bottom: 50px;
    }
}

.wrapper {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 20px;
}

.main { 
    .wrapper {
        margin-bottom: 108px;
        padding-top: 20px;
        background-color: #ffffff;
        box-shadow: 0 0 30px #090c3b;
        @media(max-width: 1140px) {
            margin-bottom: 0;
		    box-shadow: none;
        }
        @media(max-width: 767px) {
            padding-top: 0px;
        }
    }
}


.section-title { 
    display: block;
    margin-bottom: 60px; 
    font-family: 'OpenSans-Bold';
    font-size: 42px;
    line-height: 42px;
    color: #33379d;
    @media(max-width: 992px) {
        margin-bottom: 35px;
        font-size: 26px;
        line-height: 34px;
        text-align: center;
    }
}

.section-title-small { 
    display: block;
    margin-bottom: 23px; 
    font-family: 'OpenSans-Bold';
    font-size: 26px;
    line-height: 32px;
    color: #33379d;
    @media(max-width: 992px) { text-align: center;}
}


.mb-44 {margin-bottom: 44px;}


.form-field{
    width: 100%;
    padding: 0 18px;
    font-size: 14px;
    line-height: 46px;
    font-family: 'OpenSans-Light';
    color: #555555;
    border: 1px solid #e1e1e1;
    background: transparent;
    border-radius: 23px;
    transition: 0.3s ease;
    -webkit-appearance: none;
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder
    &::-moz-placeholder {
        padding: 0 18px;
        font-size: 14px;
        line-height: 46px;
    }
    &:hover,
    &:focus {
        border: 1px solid #33379d;
        color: #33379d;
    }
    &.date-field {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 48px;
        padding: 12px 18px 14px;
        font-size: 14px;
        line-height: 16px;
    }
}
textarea.form-field {padding-top: 20px;}


.tabs-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
    @media(max-width: 767px) {
        flex-flow: row wrap;
        justify-content: space-around;
    }
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 220px;
        height: 56px;
        font-family: 'OpenSans-Bold';
        font-size: 14px;
        line-height: 14px;
        color: #4f4f4f;
        background-color: #e1e1e1;
        transition: 0.3s ease;
        @media(max-width: 900px) {
            font-size: 12px;
            line-height: 12px;
        }
        &:not(:last-child) {margin-right: 1px;}
        &.active,
        &:hover {
            color: #ffffff;
            background-color: #11145d;
        }
        @media(max-width: 767px) {
            max-width: calc(50% - 10px);
            &:not(:last-child) {margin-right: 0px;} 
            margin-bottom: 5px;
        }
        @media(max-width: 500px) {
            max-width: 100%;
            margin-bottom: 5px;
        }
    }
}



@media(max-width: 722px) {
    .paragraph {width: 100%;}
    .wp-block-table {
      width: 100%;
      overflow: scroll;
    }
    .wp-block-table table {width: 682px;}
  }

.tab-cnt {
    display: none;
    &.active {display: block;}
}

.w-1100 {width: 1100px;}
.w-770 {width: 770px;}
.w-220 {width: 220px;}
.w-50 {width: 50px;}
.w-46 {width: 46px;}
.w-25p {width: 25%;}
.w-100p {width: 100%;}
.h-56 {height: 56px;}
.h-50 {height: 50px;}
.h-45 {height: 45px;}
.h-42 {height: 42px;}
.h-40 {height: 40px;}
.h-38 {height: 38px;}

.t-align-center {text-align: center;}
.t-align-left {text-align: left;}
.v-align-center {vertical-align: middle;}
.v-align-left {vertical-align: left;}

.mb-10 {margin-bottom: 10px;}

.table-block {
    @media(max-width: 1140px) {overflow-x: scroll;}
    table {
        border-collapse: collapse; /* Убираем двойные линии между ячейками */
    }
    td, td {
            padding: 9px 15px 5px; /* Поля вокруг содержимого таблицы */
            border: 1px solid #ebebeb; /* Параметры рамки */
            font-family: 'OpenSans-Light';
            font-weight: 300 !important;
            font-size: 14px;
            line-height: 14px;
            color: #252525;
            vertical-align: middle;
    }
    th {
        padding: 9px 7px 5px;
        font-family: 'OpenSans-Light';
        font-weight: 300 !important;
        font-size: 16px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.65);
        background: #33369d;
        border: 1px solid rgba(255, 255, 255, 0.65);
        vertical-align: middle;
        &.table-column-first {padding: 7px 17px 5px;}
    }
    &.table-block-search {
        td a, td{color: #33369d;}
    }
}
