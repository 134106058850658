@font-face {
	font-family: 'OpenSans-Bold';
	src: url('../css/fonts/opensans/OpenSans-Bold.woff') format('woff'),
		 url('../css/fonts/opensans/OpenSans-Bold.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'OpenSans-Regular';
	src: url('../css/fonts/opensans/OpenSans-Regular.woff') format('woff'),
		 url('../css/fonts/opensans/OpenSans-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'OpenSans-Light';
	src: url('../css/fonts/opensans/OpenSans-Light.woff') format('woff'),
		 url('../css/fonts/opensans/OpenSans-Light.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
