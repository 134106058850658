.championship {
    form {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 34px;
        @media(max-width: 930px) {flex-direction: column;}
        @media(max-width: 500px) {flex-direction: column;}
        .btn-blue {height: 38px;}
        .select-block {
            position: relative;
            width: 100%;
            max-width: 180px;
            margin-right: 12px;
            @media(max-width: 1140px) {max-width: 150px;}
            @media(max-width: 930px) {
                max-width: 75%;
                margin-bottom: 15px;
            }
            @media(max-width: 500px) {max-width: 100%}
            & svg {
                position: absolute;
                top: calc(50% - 2px);
                right: 17px;
                width: 8px;
                height: 4px;
                transition: 0.1s ease;
                & path {transition: 0.3s ease;}
            }
            .form-field {
                height: 38px;
                line-height: 38px;
            }
            &.active{
                & svg {
                    transform: rotate(180deg);fill: #33379d;
                    & path {fill: #33379d;}
                }
            }
        }
    }
    .tabs-section {
        margin-bottom: 1px;
        & button {
            max-width: 25%;
            @media(max-width: 767px) {max-width: calc(50% - 1px);}
        }
    }
    &-table{
        & th {padding: 9px 15px 5px;}
    }
    &-tours {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        & td {padding: 9px 15px 5px;}
        @media(max-width: 1100px) {flex-direction: column;}
        & .championship-tour {
            width: 100%;
            max-width: 545px;
            padding-top: 22px;
            &:not(:last-child) {margin-right: 5px;}
            @media(max-width: 1100px) {
                max-width: 100%;
                &:not(:last-child) {margin-right: 0px;}
            }
            table {
                width: 100%;
                th {
                    font-size: 14px;
                    line-height: 14px;
                }
                td {padding: 9px 5px 5px;}
            } 
        }
    }
    &-scorers {
        padding-top: 20px;
        table {width: 100%;} 
    }
    @media(max-width: 720px) {
        & table {width: 680px !important;}
    }
    .table-block {
        table {width: 100%;}
        @media(max-width: 720px) {
            overflow: auto;
            .championship-table-item {overflow: scroll;}
        }
    }
}
.championship-head {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 13px;
    @media(max-width: 560px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    & h3 {
        margin-bottom: 0;
        line-height: 26px;
        @media(max-width: 560px) {margin-bottom: 15px;}
        & span {
            font-family: 'OpenSans-Light';
            font-size: 18px;
        }
    }
    p {
        padding-bottom: 3px;
        font-family: 'OpenSans-Regular';
        font-size: 13px;
        line-height: 13px;
        color: #33379d;
    }
}
.table-list {
    width: 100%;
    .table-hidden-trigger-cnt {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 5px;
        cursor: pointer;
        font-family: 'OpenSans-Light';
        font-weight: 300 !important;
        font-size: 14px;
        line-height: 14px;
        color: #252525;
        vertical-align: middle;

        & .close-table,
        & .open-table {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 17px;
        }

        .close-table {display: none;}

        & img {max-width: 17px;}

        & .table-left-line {margin-left: 15px;}
        
        &:hover span {color: #33379d;}
        &.active {
            .open-table {display: none;}
            .close-table {display: flex;}
        }
    }
    & a {
        color: #33379d;
        transition: 0.3s ease;
        &:hover {text-decoration: underline;}
    }
    .table-row-head {
        font-family: 'OpenSans-Bold';
        color: #33369d;
        & > span {font-family: 'OpenSans-Bold';}
        & table {
            width: 100%;
            &  span {font-family: 'OpenSans-Regular';}
        }
    }
    .table-list-bg th {
        background: #E1E1E1;
        color: #252525;
    }
    .table-date {font-family: 'OpenSans-Light';}
    & .table-row-line-1 {padding: 9px 0 5px 5px;}
    & .table-row-line-2 {padding: 5px 0 0 17px;}
    & .table-row-line-3 {padding: 9px 0 5px 17px;}
    & .table-row-line-4 {
        padding: 9px 31px 5px;
        font-family: 'OpenSans-Light';
    }
    & .table-row-line-4-1 {padding: 0 0 0 33px !important;}
    @media(max-width: 730px) {
        .table-row-line-2,
        .table-row-line-3,
        .table-row-line-4,
        .table-row-line-4-1 {padding: 9px 0 5px 0 !important;}
        .championship-table-item{
            overflow: scroll;
            width: 100%;
        }
    }

}
.table-list-item-hidden {display: none;}
.table-list-item-hidden.active {display: table;}
.table-list-item-cnt {
    margin: 10px 0;
    & td {
        padding: 9px 7px 5px;
        border: 1px solid #ebebeb !important;
        font-family: 'OpenSans-Light';
        font-weight: 300 !important;
        font-size: 14px;
        line-height: 14px;
        color: #252525;
        vertical-align: middle;
    }
}

.protocol-block {
    padding-top: 22px;
    h3 {margin-bottom: 0;}
}

.protocol-info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    margin: 0 auto;
    & img {width: 100%;}
    &:hover {text-decoration: none;}
}


@media(max-width: 730px) {
    .championship-table-item{
        overflow: scroll;
        width: 100%;
    }
}