.clubs {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    & .club {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 137px;
        margin-bottom: 13px;
        padding: 0 10px;
        &-img {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 115px;
            width: 100%;
            margin-bottom: 8px;
            & img {max-width: 100%;}
        }
        &-title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;
            height: 44px;
            font-family: 'OpenSans-Regular';
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #111111;
            transition: 0.3s ease;
        }
        &:hover .club-title {color: #33379d;}
    }
}

.club-cnt {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 33px;
    @media(max-width: 767px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
    }
    &-img {
        width: 100%;
        padding-top: 11px;
        max-width: 137px;
        margin-right: 61px;
        margin-left: 19px;
        & img {max-width: 100%;}
        @media(max-width: 767px) {
            padding-top: 0;
            margin-right: 0;
            margin-left: 0;
        }
    }
    &-info {
        @media(max-width: 767px) {text-align: center;}
        & h2 {
            margin-bottom: 13px;
            padding-top: 4px;
            letter-spacing: 0.45px;
            font-family: 'OpenSans-Bold';
            font-size: 26px;
            line-height: 32px;
            color: #33379d;
        }
        & span, & a {
            font-family: 'OpenSans-Regular';
            font-size: 14px;
            line-height: 27px;
            color: #363636;
            transition: 0.3s ease;
        }
        & a {
            color: #33379d;
            text-decoration: underline;
            &:hover {
                color: #363636;
                text-decoration: none;
            }
        }
        & .club-cnt-info-head {
            font-family: 'OpenSans-Bold';
            color: #363636;
        }
    }
}

.club-section {padding-bottom: 49px;}


.toggle-row-popup{
    position: relative;
    & .row-popup-trigger {
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {
            color: #33369d;
            text-decoration: underline;
        }
    }
    & .row-popup {
        position: absolute;
        top: -28px;
        left: 139px;
        z-index: 3;
        display: none;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: 220px;
        padding: 23px 20px;
        background: #ffffff;
        border: 1px solid #33369d;
        @media(max-width: 420px) {
            top: -28px;
            left: 20px;
        }
        &.active {display: flex;}
        .close-row-popup {
            position: absolute;
            top: 8px;
            right: 9px;
            display: block;
            z-index: 4;
            cursor: pointer;
            width: 10px;
            height: 10px;
            opacity: 0.5;
            transition: 0.3s ease;
            &:hover {opacity: 1;}
            & img {width: 100%;}
        }
        &-line {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: 'OpenSans-Regular';
            font-weight: 300 !important;
            font-size: 12px;
            line-height: 12px;
            & span {display: block;}
            & span:first-child {
                margin-right: 5px;
                font-family: 'OpenSans-Bold';
            }
            &:nth-child(1),
            &:nth-child(2) {margin-bottom: 9px;}
        }
    }
}
