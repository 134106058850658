.news-list {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
  & .news-item {width: 50%;}
}
.news-item {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 540px;
  margin-bottom: 22px;
  .news-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 186px;
    height: 100%;
    & img {
      width: 100%;
      height: 100%;
      min-height: 143px;
    }
  }
  .news-cnt {
    width: 100%;
    padding: 20px 16px 17px 27px;
    background: #ffffff;
    box-shadow: 0 0 15px rgba(9, 12, 59, 0.2);  
  }
  .news-head {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    margin-bottom: 6px; 
    font-family: 'OpenSans-Regular';
    font-size: 16px;
    line-height: 21px;
    height: 42px;
    color: #33379d;
    transition: 0.3s ease;
    &:hover {color: #585858;}
  }
  .news-desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    margin-bottom: 9px; 
    font-family: 'OpenSans-Regular';
    font-size: 13px;
    line-height: 17px;
    height: 36px;
    color: #585858;
  }
  .news-date {
    display: block;
    font-family: 'OpenSans-Regular';
    font-size: 14px;
    text-align: right;
    color: #363636;
  }
}

@media(max-width: 1100px){
  .news-list .news-item {max-width: 470px;}
}
@media(max-width: 992px){
  .news-list {
    flex-flow: row wrap;
    & .news-item {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media(max-width: 767px){
  .last-news {display: none;}
  .news-list {
    & .news-item {
      & .news-desc {
        display: none;
      }
    }
  }
}