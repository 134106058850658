.breadcrumps {
    display: flex;
    align-items: center;
		justify-content: flex-start;
		padding-bottom: 14px;
		@media(max-width: 992px) {display: none;}
    & a,
    & span {
      font-family: 'OpenSans-Regular';
      font-size: 12px;
			line-height: 12px;
			color: #585858;
			transition: 0.3s ease;
		}
		
		& a,
		& img {
			margin-right: 6px;
			&:hover {color: #3f43af;}
		}

		& img {
			width: 4px;
			height: 8px;
		}
}