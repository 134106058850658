@import "header.scss";
@import "breadcrumps.scss";
@import "pagesLinks.scss";
@import "footer.scss";
@import "blocks/videosAndPhotos.scss";
@import "blocks/news.scss";
@import "blocks/newsSLider.scss";
@import "blocks/article.scss";
@import "blocks/decision.scss";
@import "blocks/clubs.scss";
@import "blocks/personnel.scss";
@import "blocks/contacts.scss";
@import "blocks/search.scss";
@import "error.scss";
@import "blocks/logo.scss";
@import "blocks/partners.scss";
@import "blocks/championship.scss";
@import "blocks/protocol.scss";
@import "blocks/gimn.scss";