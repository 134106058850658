.partners-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
    & a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 25%;
        margin-bottom: 47px;
        font-family: 'OpenSans-Regular';
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #333333;
        @media(max-width: 992px){
            width: 50%;
        }
        @media(max-width: 500px){
            width: 100%;
            margin-bottom: 40px;
            &:last-child {margin-bottom: 0;}
        }
        &:hover span {color: #33379d;}
        & .partners-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 256px;
            height: 120px;
            margin-bottom: 10px;
            @media(max-width: 500px) {height: auto;}
            & img {
                width: 100%;
                &.partners-1 {max-width: 106px;}
                &.partners-2 {max-width: 231px;}
                &.partners-3 {max-width: 82px;}
                &.partners-4 {max-width: 71px;}
                &.partners-5 {max-width: 118px;}
                &.partners-6 {max-width: 256px;}
                &.partners-7 {max-width: 110px;}
                &.partners-8 {max-width: 193px;}
            }
        }
    }
}
