.searching-player-section {padding-bottom: 28px;}
.search-block {
    @media(min-width: 993px) {padding-top: 11px;}
    form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 40px;
        @media(max-width: 930px) {
            flex-flow: row wrap;
            justify-content: space-around;
        }
        @media(max-width: 500px) {flex-direction: column;}
        .btn-blue {height: 45px;}
        .btn-clear {
            @media(max-width: 1140px) {padding: 10px 5px;}
        }
        .form-field {
            max-width: 180px;
            @media(max-width: 1140px) {max-width: 150px;}
            @media(max-width: 930px) {
                max-width: calc(50% - 10px);
                margin-bottom: 15px;
            }
            @media(max-width: 500px) {max-width: 100%}
        }
    }
    .table-block {
        table {
            @media(max-width: 570px) {width: 530px}
        }
    }
}

.searching-player {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media(max-width: 767px){
        flex-direction: column;
        align-items: center;
    }
    &-info {
        width: 100%;
        max-width: 310px;
        margin-right: 19px;
        @media(max-width: 767px){
            margin-right: 0;
            margin-bottom: 20px;
            display: flex;
            max-width: 100%;
        }
        @media(max-width: 500px){
            flex-direction: column;
            align-items: center;
        }
    }
    &-img {
        width: 100%;
        margin-bottom: 19px;
        @media(max-width: 767px){
            max-width: 310px;
            margin-right: 19px;
            margin-bottom: 0;
        }
        @media(max-width: 500px){
            margin-right: 0px;
            margin-bottom: 20px;
        }
        & img {width: 100%;}
    }
    &-history {
        @media(max-width: 767px) {min-width: 295px;}
        @media(max-width: 500px) {
            min-width: 0px;
            width: 100%;
            max-width: 310px;
        }
        & p {
            font-family: 'OpenSans-Bold';
            font-size: 12px;
            line-height: 12px;
            color: #363636;
            & span {
                font-family: 'OpenSans-Regular';
                color: #b71c1c;
            }
        }
        & table {
            & tr {
                & td{
                    padding-bottom: 16px;
                    font-family: 'OpenSans-Regular';
                    font-size: 12px;
                    line-height: 12px;
                    color: #363636;
                    &:nth-child(2) {padding: 0 10px 16px;}
                    @media(max-width: 400px) {
                        font-size: 11px;
                        line-height: 10px;
                    }
                }
            }
        }
    }
    &-cnt {
        width: 100%;
        max-width: 770px;
        @media(max-width: 1150px) {overflow: scroll;}
        h2 {
            margin-bottom: 17px;
            font-family: 'OpenSans-Bold';
            font-size: 26px;
            line-height: 26px;
            color: #33379d;
            @media(max-width: 767px){text-align: center;}
        }
        p {
            margin-bottom: 30px;
            font-family: 'OpenSans-Regular';
            font-size: 12px;
            line-height: 12px;
            color: #363636;
            @media(max-width: 767px){text-align: center;}
            & > span {
                font-family: 'OpenSans-Bold';
                &:nth-child(1) {margin-right: 10px;}
                & > span {font-family: 'OpenSans-Regular';}
            }
        }
        & .tabs-section {
            margin-bottom: 1px;
            @media(max-width: 500px) {flex-direction: column;}
            & button {
                max-width: 50%;
                @media(max-width: 500px) {max-width: 100%;}
            }
        }
    }
    &-table {
        & th {
            padding: 5px 3px 5px;
            font-size: 14px;
            line-height: 14px;
            &.table-first-row {
                padding-left: 15px;
                cursor: pointer;
            }
        }
        & td {
            padding: 9px 3px 5px;
            font-size: 14px;
            line-height: 14px;
            &.table-first-row {padding-left: 15px;}
        }
        & td.table-first-row {
            cursor: pointer;
            & span {
                position: relative;
                display: block;
                padding-left: 35px;
                &::after {
                    content: '';
                    position: absolute;
                    left: 7px;
                    top: calc(50% - 1.5px);
                    display: block;
                    width: 17px;
                    height: 3px;
                    background: #33369D;
                    transition: 0.15s ease;
                    opacity: 0;
                }
                &::before {
                    content: '';
                    position: absolute;
                    width: 17px;
                    height: 17px;
                    left: 7px;
                    top: calc(50% - 8.5px);
                    display: block;
                    background: url('../images/open-table.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    transition: 0.15s ease;
                    opacity: 1;
                }
            }
            &.active {
                span::after {opacity: 1;}
                span::before {opacity: 0;}
            }
        }
        & .table-personal {
            & td {
                
                font-size: 14px;
                line-height: 14px;
                &.no-border {
                    padding: 0;
                    border: none;
                }
            }
        }
        & .table-personal-gray {
            & th {
                background: #E1E1E1;
                color: #252525;
            }
            & td {
                padding: 2px 3px 2px;
                font-size: 12px;
                line-height: 12px;
            }
            .table-personal-gray-pad {padding-left: 8px;}
        }
    }
}


.table-hidden-item-wrap {
    display: none;
    &.active {display: table-row;}
    .table-hidden-item {
        padding: 0 !important;
        border: none !important;
        & tr:first-child td {border-top: none;}
        // & table tr:last-child td {border-bottom: none;}
    }
}

.table-hidden-trigger.active span {color: #33379d;}