.article-title {
  margin-bottom: 18px; 
  font-family: 'OpenSans-Bold';
  font-size: 26px;
  line-height: 32px;
  color: #33379d;
}

.paragraph,
.article-date-layout p{
  margin-bottom: 20px; 
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  line-height: 21px;
  color: #363636;
}
.paragraph span {
  font-family: 'OpenSans-Bold';
  color: #1e2276;
}
.paragraph-light{
  font-size: 14px;
  font-style: italic;
}

.paragraph-mb-40 {
  @media(min-width: 993px) {margin-bottom: 40px;}
}

.article-img-main {
  width: 100%;
  max-width: 665px;
  margin-bottom: 15px;
  margin-right: 20px;
  float: left;
  &.about-img {
    max-width: 225px;
    margin-bottom: 0px;
    margin-right: 126px;
  }
}

.article-gallery {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
  & a {
    position: relative;
    display: block;
    width: 100%;
    max-width: 260px;
    margin-bottom: 20px;
    max-height: 193px;
    & img {width: 100%;}
    & span {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.4);
      transition: 0.3s ease;
      & svg {
          width: 40px;
          height: 40px;
          fill: #ffffff;
      }
      @media(max-width: 1100px) {
          opacity: 1;
          background-color: rgba(0, 0, 0, 0.25);
      }
    }
    &:hover {
     	& span {opacity: 1;}
    }
  }
}

.article-date-layout {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
    & p {margin-bottom: 0;}
}

.article-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & .panel-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      &:not(:last-child) {margin-right: 5px;}
      & img {width: 100%;}
    }
    & .print,
    & .pdf {
      display: flex;
      align-items: center;
			justify-content: center;
			margin-left: 25px;
      font-family: 'OpenSans-Bold';
      font-size: 16px;
      line-height: 16px;
			color: #d9d9d9;
			& img {margin-right: 8px;}
		}
		.print img {width: 25px;}
		.pdf img {width: 23px;}
	}
	& .article-nav {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
    max-width: 385px;
		& a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			max-width: 186px;
			height: 31px;
			font-family: 'OpenSans-Light';
			font-size: 16px;
			line-height: 16px;
			color: #54504f;
			border: 1px solid #b7b7b7;
			transition: 0.3s ease;
			&.article-nav-prev {
				margin-right: 12px;
				& span {
          &::before{
            content: "<";
            margin-right: 7px;
          }
        }
			}
			&.article-nav-next {
				span {
          &::after{
            content: ">";
            margin-left: 7px;
          }
        }
      }
			&:hover {
				border: 1px solid #3f43af;
				color: #3f43af;
			}
		}
	}
}

@media(max-width: 1100px){
	.article-gallery {justify-content: space-around;}
	.news-article:not(.about-info) {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		.article-img-main {
			margin: 0 auto 20px;
			order: 2;
			float: none;
		}
		.article-title {
			order: 1;
			width: 100%;
			text-align: center;
		}
		.paragraph {order: 3;}
		.article-gallery {order: 4;}
		.article-date-layout{
			width: 100%;
			order: 5;
		}
		.article-panel{
			width: 100%;
			order: 6;
		}
	}
}

@media(max-width: 992px) {
  
	.about-info {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		.article-img-main {
      display: block;
			margin: 0 auto 20px;
			float: none;
		}
		.article-title {
			width: 100%;
			text-align: center;
		}
	}
	.article-panel {
		flex-direction: column;
		.article-panel-links {margin-bottom: 15px;}
	}
	.article-date-layout {justify-content: center;}
	.paragraph br {
		display: none;
		margin-bottom: 15px;
		font-size: 14px;
    line-height: 19px;
	}
	.paragraph-light {font-size: 12px;}
	.article-title {
		font-size: 20px;
    line-height: 26px;
	}
}
@media(max-width: 412px) {
	.article-panel-links .print, .article-panel-links .pdf {display: none;}
	.article-panel .article-nav a {
		font-size: 12px;
		line-height: 12px;
	}
}
