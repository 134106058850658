.error-page {
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100vh;
        .error-page-cnt {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: column;
            padding-right: 50px;
        }
        .error-title-top {
            font-family: 'OpenSans-Bold';
            font-size: 63px;
            line-height: 82px;
            text-align: center;
            color: #ffffff;
        }
        .error-title-middle {
            font-family: 'OpenSans-Bold';
            font-size: 296px;
            line-height: 218px;
            margin-bottom: 44px;
            text-align: center;
            color: #ffffff;
        }
        .error-title-subtitle {
            margin-bottom: 23px;
            font-family: 'OpenSans-Regular';
            font-size: 19px;
            line-height: 25px;
            text-align: center;
            color: #ffffff;
        }
    }
}

@media(max-width: 1024px){
    .error-page .wrapper{justify-content: center;}
    .error-page .wrapper .error-page-cnt {padding-right: 0;}
    .error-page .wrapper .error-title-middle {
        font-size: 150px;
        line-height: 150px;
        margin-bottom: 0;
    }
    .error-page .wrapper .error-title-top{
        font-size: 40px;
        line-height: 40px;
    }
    .error-page .wrapper .error-title-subtitle {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 20px;
        & br {display: none;}
    }
}


@media(max-width: 767px) {
    .error-page .wrapper .error-title-top{
        font-size: 30px;
        line-height: 30px;
    }
    .error-page .wrapper .error-title-subtitle {
        font-size: 14px;
        line-height: 17px;
    }
}