.personnel {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
}

.person {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    max-width: 544px;
    &-card {
        max-width: 100%;
        width: 100%;
    }
    @media(max-width: 992px) {
        width: 100%;
    }
    @media(max-width: 550px) {
        flex-direction: column;
        align-items: center;
    }
    &:not(:last-child){margin-bottom: 37px;}
    &-img {
        display: block;
        width: 100%;
        max-width: 200px;
        margin-right: 22px;
        & img {width: 100%;}
        @media(max-width: 550px) {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
    &-position {
        position: relative;
        margin-top: 11px;
        margin-bottom: 29px;
        padding-left: 14px;
        font-family: 'OpenSans-Bold';
        font-size: 32px;
        line-height: 32px;
        color: #33379d;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: calc(50% - 27px);
            width: 4px;
            height: 54px;
            background-color: #33379d;
        }
        &-medium {
            height: 74px;
            margin-bottom: 0;
            margin-top: 0;
            padding-top: 10px;
            font-size: 24px;
            &::before {top: 0px;}
            @media(max-width: 1140px) {
                height: 52px;
                margin-bottom: 20px;
                padding-top: 16px;
                font-size: 18px;
                line-height: 26px;
            }
            @media(max-width: 992px) {
                height: 74px;
                margin-bottom: 0;
                margin-top: 0;
                padding-top: 10px;
                font-size: 24px;
                line-height: 32px;
            }
        }
        &-small {
            height: auto;
            margin-bottom: 12px;
            margin-top: 0;
            padding-left: 0;
            padding-top: 0px;
            font-size: 16px;
            line-height: 26px;
            &::before {display: none;}
        }
        @media(max-width: 550px) {
            margin-top: 0;
            margin-bottom: 15px;
            padding-top: 0;
            padding-left: 0;
            height: auto;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            &::before {display: none;}
        }
    }
    &-born {
        font-family: 'OpenSans-Regular';
        font-size: 16px;
        line-height: 18px;
        color: #3f3f3f;
        @media(max-width: 550px) {
            text-align: center;
        }
    }
    &-name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -webkit-box-orient: vertical;
        height: 64px;
        margin-bottom: 20px; 
        font-family: 'OpenSans-Bold';
        font-size: 24px;
        line-height: 32px;
        color: #252525;
        transition: 0.3s ease;
        &:hover {color: #33379d;}
        &-big {height: auto;}
        &-medium {
            height: 48px;
            margin-bottom: 9px;
            font-size: 18px;
            line-height: 24px;
        }
        &-small {
            margin-top: 16px;
            margin-bottom: 11px;
        }
        @media(max-width: 550px) {
            height: auto;
            margin-bottom: 15px;
            text-align: center;
            margin-top: 0;
        }
    }
    &-info {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 21px;
        @media(max-width: 550px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px;
            text-align: center;
        }
        &-item {
            width: 100%;
            max-width: 50%;
            font-size: 16px;
            line-height: 16px;
            color: #363636;
            @media(max-width: 550px) {
                max-width: 100%;
                margin-bottom: 17px;
            }
        }
        &-title {
            margin-bottom: 17px; 
            font-family: 'OpenSans-Bold';
        }
        &-date {font-family: 'OpenSans-Regular';}
        &-medium {
            & .person-info-title {margin-bottom: 11px;}
        }
    }
    &-text {
        & p {
            font-family: 'OpenSans-Regular';
            font-size: 16px;
            line-height: 20px;
            color: #363636;
            &:not(:last-child) {margin-bottom: 12px;}
            & span {
                font-family: 'OpenSans-Bold';
                color: #33379d;
            }
            @media(max-width: 1200px) {br {display: none;}}
            @media(max-width: 550px) {
                text-align: center;
            }
        }
    }
    & .btn {
        @media(max-width: 550px) {
            margin: 0 auto
        }
    }
}

.person-card {
    & .person-img {
        margin-right: 18px;
        @media(max-width: 550px) {margin-right: 0;}
    }
    .person-name {
        margin-bottom: 16px;
        padding-top: 5px;
        @media(max-width: 550px) {padding-top: 0;}
    }
}
.personnel-img {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 262px;
    & img {width: 100%;}
    @media(max-width: 992px) {
        display: none;
    }
}

